import BaSpinner from 'react-bootstrap/Spinner';

interface PropsI {
    size?: "sm";
    className?: string;
    style?: { [key: string]: string | number }
}

export const Loader = ({ size, className, style = {} }: PropsI) => {

    const defaultMargin = "2rem 0 0 0"

    return (
        <div style={{ opacity: 0.7, margin: defaultMargin, ...style }} className={`d-flex justify-content-center ${className ? className : ""}`}>
            <BaSpinner animation="border" role="status" size={size} variant="dark">
                <span className="visually-hidden">Loading...</span>
            </BaSpinner>
        </div>
    )
}

