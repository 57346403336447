import React from 'react'

export const MetricsSection = () => {
    return (
        <>
            <div className="row mb-4">
                <div className="col-md-12 col-12 my-4">
                    <h2>Uso de la plataforma</h2>
                </div>
                <div className="col-md-4 col-sm-6 col-12">
                    <div className="card dashboard">
                        <div className="card-body">
                            <p className="dc-title">API Requests</p>
                            <p className="dc-subtitle">Cantidad de consultas a la API</p>
                            <p className="dc-requests">10.552</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-12">
                    <div className="card dashboard">
                        <div className="card-body">
                            <p className="dc-title">Tokens</p>
                            <p className="dc-subtitle">Cantidad de tokens utilizados</p>
                            <p className="dc-requests">125.327</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12 col-12 my-4">
                    <h2>Conversaciones</h2>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-4">
                    <div className="card dashboard dbh-50">
                        <div className="card-body">
                            <p className="dc-title">Conversaciones</p>
                            <p className="dc-subtitle">Cantidad de conversaciones</p>
                            <p className="dc-requests">35.795</p>
                            <a href="#" className="viewDetail">Ver detalle</a>
                        </div>
                    </div>
                    <div className="card dashboard dbh-50">
                        <div className="card-body">
                            <p className="dc-title">Mensajes </p>
                            <p className="dc-subtitle">Media de mensajes por conversación</p>
                            <p className="dc-requests">12,5</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-4">
                    <div className="card dashboard dbh-100">
                        <div className="card-body">
                            <p className="dc-title">Fuente</p>
                            <p className="dc-subtitle">Distribución de fuentes</p>
                            <div className="fuentesRow">
                                <div className="row">
                                    <div className="col-md-8 col-8">
                                        <p>Web Clientes</p>
                                    </div>
                                    <div className="col-md-4 col-4 text-end">
                                        <p>16.023 (44%)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="fuentesRow">
                                <div className="row">
                                    <div className="col-md-8 col-8">
                                        <p>WhatsApp</p>
                                    </div>
                                    <div className="col-md-4 col-4 text-end">
                                        <p>12.695 (35%)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="fuentesRow">
                                <div className="row">
                                    <div className="col-md-8 col-8">
                                        <p>Mobile Android</p>
                                    </div>
                                    <div className="col-md-4 col-4 text-end">
                                        <p>6.042 (17%)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="fuentesRow">
                                <div className="row">
                                    <div className="col-md-8 col-8">
                                        <p>Mobile iOS</p>
                                    </div>
                                    <div className="col-md-4 col-4 text-end">
                                        <p>1.035 (4%)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12 col-12 my-4">
                    <h2>Performance</h2>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-4">
                    <div className="card dashboard dbh-50">
                        <div className="card-body">
                            <p className="dc-title">Duración</p>
                            <p className="dc-subtitle">Tiempo medio de conversación</p>
                            <p className="dc-requests">1m 30s</p>
                        </div>
                    </div>
                    <div className="card dashboard dbh-50">
                        <div className="card-body">
                            <p className="dc-title">Precisión</p>
                            <p className="dc-subtitle">Índice de precisión de respuestas.</p>
                            <p className="dc-requests">95%</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-4">
                    <div className="card dashboard dbh-100">
                        <div className="card-body">
                            <p className="dc-title">Temas</p>
                            <p className="dc-subtitle">Listado de temas más frecuentes</p>
                            <div className="fuentesRow">
                                <div className="row">
                                    <div className="col-md-8 col-8">
                                        <p>Consultas de facturación</p>
                                    </div>
                                    <div className="col-md-4 col-4 text-end">
                                        <p>640 (36%)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="fuentesRow">
                                <div className="row">
                                    <div className="col-md-8 col-8">
                                        <p>Venta de nuevos productos</p>
                                    </div>
                                    <div className="col-md-4 col-4 text-end">
                                        <p>450 (25%)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="fuentesRow">
                                <div className="row">
                                    <div className="col-md-8 col-8">
                                        <p>Administrativos</p>
                                    </div>
                                    <div className="col-md-4 col-4 text-end">
                                        <p>353 (20%)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="fuentesRow">
                                <div className="row">
                                    <div className="col-md-8 col-8">
                                        <p>Reclamos de servicio</p>
                                    </div>
                                    <div className="col-md-4 col-4 text-end">
                                        <p>322 (19%)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
