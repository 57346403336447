import { RecursoI } from './ChatScreen';

export const RECURSOS_HARDCODE_SEGUROS: RecursoI[] = [
    {
        type: "EXCEL",
        title: "Excel de Cotización",
        status: "SYNCED",
    },
    {
        type: "MySQL",
        title: "SQLSERVER",
        status: "SYNCED",
    },
    {
        type: "PDF",
        title: "PDF",
        status: "SYNCED",
    },
    {
        type: "API",
        title: "API de Seguro",
        status: "SYNCED",
    }
] 