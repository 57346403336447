import { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/authSlice/authSlice";
import "./header.css";
import { PRIVATE_ROUTE } from "../../routes/privateRoutes";
import { NavLink } from "react-router-dom";

export const navOptions = [
  { path: PRIVATE_ROUTE.HOME, label: "Home", },
  { path: PRIVATE_ROUTE.FAQ, label: "FAQ" },
  { path: PRIVATE_ROUTE.DASH, label: "Dashboard" },
]

export const Header = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.authData.auth);
  const isLogged = auth?.user;
  const logoHeader = "/assets/img/logo-header.svg";

  const [isOpenHamburguer, setIsOpenHamburguer] = useState(false)

  return (
    <header>
      <nav id="myNavbar" className="navbar navbar-expand-lg fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/home"><img src="assets/img/logo_GoCloud-color.svg" width="100%" height="100%" alt="GoCloud Logo" /></a>
          <button
            style={{ zIndex: 10000 }}
            className="navbar-toggler navbar-toggler-button menu-btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navigation"
            aria-controls="navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setIsOpenHamburguer(st => !st)}
          >
            <div className={`animated-hamburger ${isOpenHamburguer ? "open" : ""}`}><span></span><span></span><span></span></div>
          </button>
          <div className="collapse navbar-collapse" id="navigation">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">

              {navOptions.map(ctOp =>
                <li className="nav-item">
                  <NavLink
                    to={ctOp.path}
                    className={({ isActive }: { isActive: boolean }) => "nav-link" + (isActive ? " active" : "")}
                  >
                    {ctOp.label}
                  </NavLink>
                </li>
              )}

              <li className="nav-item">
                <Button variant="link" className="nav-link" onClick={() => dispatch(logout())}>Log out <i className="bi bi-box-arrow-in-right ms-1"></i></Button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header >
  );
};
