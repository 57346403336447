import React from 'react'

export const ConversacionesSection = () => {
    return (
        <div>
            <div className="row mb-4">
                <div className="col-md-12 col-12 my-4">
                    <h2>Lisatdo de conversaciones</h2>
                </div>
                <div className="col-md-8 col-sm-8 col-12 mb-4">
                    <div className="card dashboard dbh-100">
                        <div className="card-body">
                            <p className="dc-title">30 de Septiembre</p>
                            <div className="conversationRow title d-none d-sm-block">
                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <p>Fecha</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p>Duración</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p>Mensajes</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p>Fuente</p>
                                    </div>
                                </div>
                            </div>
                            <a href="#" className="conversationRow text">
                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <p className="mobileTableTitle">Fecha</p>
                                        <p>30/10/2023 08:25AM</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Duración</p>
                                        <p>1m 40s</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Mensajes</p>
                                        <p>4</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Fuente</p>
                                        <p>Web</p>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="conversationRow text">
                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <p className="mobileTableTitle">Fecha</p>
                                        <p>30/10/2023 08:28AM</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Duración</p>
                                        <p>5m 20s</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Mensajes</p>
                                        <p>12</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Fuente</p>
                                        <p>Web</p>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="conversationRow text">
                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <p className="mobileTableTitle">Fecha</p>
                                        <p>30/10/2023 10:10AM</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Duración</p>
                                        <p>2m 02s</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Mensajes</p>
                                        <p>5</p>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <p className="mobileTableTitle">Fuente</p>
                                        <p>Web</p>
                                    </div>
                                </div>
                            </a>
                            <div className="row mt-5">
                                <a href="#" className="btn btn-link">Cargar más conversaciones</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
