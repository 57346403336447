import { RecursoI } from './ChatScreen';

export const RECURSOS_HARDCODE_JUC: RecursoI[] = [
    {
        type: "JIRA",
        title: "Jira",
        status: "SYNCED",
    },
    {
        type: "REDMINE",
        title: "Redmine",
        status: "SYNCED",
    },
    {
        type: "DISCORD",
        title: "discord",
        status: "SYNCED",
    },
    {
        type: "MySQL",
        title: "MySQL",
        status: "SYNCED",
    },
] 