import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { PRIVATE_ROUTE } from "../../routes/privateRoutes";
import { ChatMsgI, ConversacionChat } from "./Components/ConversacionChat/ConversacionChat";
import { RecursosTable } from "./Components/RecursosTable/RecursosTable";
import { CHAT_HARDCODE } from "./chatHardcode";
import "./chatScreen.css";

import { RECURSOS_HARDCODE } from "./recursosHardcode";
import { Loader } from '../../components/Loader/Loader';
import { IaAvatar } from "./Components/ConversacionChat/components/IaChatMsg";
import { RECURSOS_HARDCODE_SEGUROS } from "./recursosHarcodeSeguros";
import { RECURSOS_HARDCODE_JUC } from "./recursosHardcodeJUC";

export type RecursoType = "PDF" | "API" | "drive" | "URL" | "REDMINE" | "JIRA" | "MySQL" | "EXCEL" | "DISCORD"
export interface RecursoI {
  type: RecursoType;
  title: string;
  subtitle?: string;
  status: "SYNCED" | "PENDING";
}

export const ChatScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  let location = useLocation();
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const dataL = { consulta: inputValue, tipoConsulta: "" };
  const [model, setModel] = useState("");
  const [urlModel, setUrlModel] = useState("");

  const mensajeBienvenidaIa = "Hola, ¿En que puedo ayudarte?";
  const mensajeBienvenidaSegurosIa = "Soy tu asistente virtual experto en seguros. Con más de 10 años de experiencia en la industria aseguradora, puedo ayudarte con una amplia gama de seguros, incluyendo seguros de vida, salud, automóviles y más. Estoy aquí para ayudarte en todo lo relacionado con seguros. Mi objetivo es proporcionarte soluciones y asesoramiento personalizado para tus necesidades aseguradoras. Me mantengo actualizado constantemente con las últimas regulaciones y tendencias en seguros. Siempre estoy aprendiendo para ofrecerte la información más actualizada y relevante. Puedo ofrecerte recomendaciones específicas de seguros según tu situación y requisitos. Cuéntame tus necesidades y te guiaré hacia las mejores opciones de cobertura. Si alguna vez no tengo la respuesta exacta, te indicaré cómo obtener más información o resolver tu consulta. Mi objetivo es ser transparente y claro en cada interacción. Puedo proporcionarte detalles sobre coberturas, exclusiones, procesos de reclamación, términos y condiciones, y cualquier otra información relacionada con seguros que necesites. Siempre saludo cordial y estoy dispuesto a ayudarte. Me gusta dar información útil y precisa.";
  const mensajeBienvenidaJUCIa ="Soy tu asistente virtual para resolver tickets de bases de datos de la aplicación EJE del Consejo de la Magistratura. Mi objetivo es proporcionarte la mejor ayuda posible y generar consultas precisas para resolver los problemas. Actitud Servicial: Estoy aquí para ayudarte con cualquier problema relacionado con las bases de datos de la aplicación EJE. Mi compromiso es ofrecerte soluciones efectivas y rápidas. Conocimiento Profundo: Tengo un amplio conocimiento sobre la estructura de la base de datos de la aplicación EJE y las consultas SQL necesarias para resolver diferentes tipos de tickets. Generación de Consultas (Queries) Correctas: Puedo generar consultas SQL correctas y eficientes para abordar problemas específicos en la base de datos de la aplicación EJE. Procedimientos Estándar: Sigo procedimientos estándar para el manejo de tickets de bases de datos, asegurando la integridad de los datos y la resolución precisa de problemas. Resolución Efectiva: Mi objetivo es resolver tus tickets de bases de datos de manera efectiva y satisfactoria, brindándote la información necesaria para solucionar cualquier inconveniente.";
  const newIaMsg: ChatMsgI = {
    remitente: "IA",
    mensaje: mensajeBienvenidaSegurosIa,
    adjuntos: undefined
  }

  const [recursos, setRecursos] = useState<RecursoI[]>(RECURSOS_HARDCODE)
  const [conversacion, setConversacion] = useState<ChatMsgI[]>([]);

  useEffect(() => {
    if (location.pathname == "/chat-juc") {
      // debugger;
      setModel("JUC");
      setUrlModel(
        "https://api.vcsmart.test.espinlabs.com.ar/1.0/backend/notificaciones/ia-juc"
      );

      const newIaMsgJUC: ChatMsgI = {
        remitente: "IA",
        mensaje: mensajeBienvenidaJUCIa,
        adjuntos: undefined
      }
      setConversacion([newIaMsgJUC]);

      setRecursos(RECURSOS_HARDCODE_JUC);
    } else if (location.pathname == "/chat-seguro") {
      // debugger;
      setModel("Seguros");
      setUrlModel(
        "https://api.vcsmart.test.espinlabs.com.ar/1.0/backend/notificaciones/ia-seguros"
      );
      const newIaMsgSeguros: ChatMsgI = {
        remitente: "IA",
        mensaje: mensajeBienvenidaSegurosIa,
        adjuntos: undefined
      }
      setConversacion([newIaMsgSeguros]);
      setRecursos(RECURSOS_HARDCODE_SEGUROS);
    } else {
      // debugger;
      setModel("Seguros");
      setUrlModel(
        "https://api.vcsmart.test.espinlabs.com.ar/1.0/backend/notificaciones/ia-seguros"
      );
      const newIaMsgJUC: ChatMsgI = {
        remitente: "IA",
        mensaje: mensajeBienvenidaIa,
        adjuntos: undefined
      }
      setConversacion([newIaMsgJUC]);
      setRecursos(RECURSOS_HARDCODE_SEGUROS);
    }
  }, []);

  function enviarDatos() {
    if (!(inputValue.length > 0)) {
      return;
    }
    setInputValue("");

    const newUserMsg: ChatMsgI = {
      remitente: "user",
      mensaje: dataL.consulta,
    }
    setConversacion(st => ([...st, newUserMsg]))
    setIsLoading(true)

    fetch(urlModel, {
      method: "POST", // Método HTTP que quieres utilizar
      headers: {
        "Content-Type": "application/json", // Tipo de contenido que estás enviando
      },
      body: JSON.stringify(dataL), // Convertir los datos a formato JSON
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al enviar datos al servidor");
        }

        return response.json(); // Procesar la respuesta del servidor si es necesario
      })
      .then((data) => {
        console.log("Datos enviados exitosamente:", data);
        const vieneConArchivosAdjuntos = Math.random() < 0.5
        const newIaAdjuntos: RecursoI[] = [
          {
            type: "PDF",
            title: "Politica de Vac..",
            status: "PENDING",
          },
          {
            type: "drive",
            title: "Ausencias",
            status: "PENDING",
          }
        ]

        const newIaMsg: ChatMsgI = {
          remitente: "IA",
          mensaje: data.tipoConsulta,
          adjuntos: vieneConArchivosAdjuntos ? newIaAdjuntos : undefined
        }
        setConversacion(st => ([...st, newIaMsg]))
        setIsLoading(false);

        // Hacer algo con la respuesta del servidor si es necesario
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    enviarDatos();
  };

  return (
    <>
      <main className="flex-shrink-0">
        <section id="chatSection">
          <div className="container-fluid g-0">
            <div className="row g-0">
              <div className="col-xl-8 col-lg-7 col-12 bg-chat">
                <div className="chatContainer">
                  <nav className="breadcrumbContainer" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href={PRIVATE_ROUTE.HOME}>Oraculos</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href={PRIVATE_ROUTE.HOME}>{model}</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Chat
                      </li>
                    </ol>
                  </nav>
                  <div className="chatInputContainer">

                    <ConversacionChat conversacion={conversacion} />
                    {
                      isLoading && <div className="d-flex" style={{ alignItems: "center", justifyContent: "start", }}>
                        <IaAvatar />
                        <Loader style={{ margin: 0 }} />
                      </div>
                    }
                  </div>
                  <div className="cb_container">
                    <div className="chatBubble cb_input">
                      <form className="input-group chatInput" onSubmit={handleSubmit}>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          value={inputValue}
                          placeholder="Escribe aquí..."
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          disabled={isLoading}
                        />
                        <Button variant="link" type="submit" className="send-chat-button" disabled={isLoading}>
                          <i className="bi bi-cursor"></i>
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-12">
                <div className="sideContainer">
                  <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
                    <Row>
                      <div className="col-md-12 col-12">
                        <div className="loginLogo">
                          <img
                            src="assets/img/logo_GoCloud-color.svg"
                            width="100%"
                            alt="GoCloud AI"
                          />
                          <span className="logoAppend">IA Demo</span>
                        </div>
                      </div>
                      <div className="col-md-12 col-12 mb-3">
                        <div className="sideCard">
                          <RecursosTable recursos={recursos} />
                        </div>
                      </div>
                    </Row>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main >
    </>
  );
};