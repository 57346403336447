import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PRIVATE_ROUTE } from "../../routes/privateRoutes";

const Home = () => {
  const navigate = useNavigate();

  return (
    <section id="homeSection" className="bg-chat">
      <div className="container g-0">
        <div className="row g-0">
          <div className="col-xl-12 col-lg-10 col-md-12 col-10 mx-auto">
            <div className="row">
              <div className="col-md-12 col-12 mb-4">
                <div className="loginLogo">
                  <img
                    src="assets/img/logo_GoCloud-color.svg"
                    width="100%"
                    alt="GoCloud AI"
                  />
                  <span className="logoAppend">Oráculo</span>
                </div>
              </div>
              <Col>
                <Row xl={3} lg={2}>
                  <Col>
                    <Card className="chatCard" onClick={() => navigate(PRIVATE_ROUTE.CHAT_UNO)}>
                      <Card.Body>
                        <h6>JUC</h6>
                        <h3>Sistema de ticket de soporte de base de datos</h3>
                        <div className="labelContainer">
                          <span className="badge badge-lg">
                            <div className="icon sm prompts"></div>{" "}
                            <strong>+860 </strong>recursos
                          </span>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <Button variant="link">
                          Comenzar chat
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="chatCard" onClick={() => navigate(PRIVATE_ROUTE.CHAT_DOS)}>
                      <Card.Body>
                        <h6>Seguros</h6>
                        <h3>Asistente de Seguros</h3>
                        <div className="labelContainer">
                          <span className="badge badge-lg">
                            <div className="icon sm prompts"></div>{" "}
                            <strong>+140 </strong>recursos
                          </span>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <Button variant="link">
                          Comenzar chat
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="chatCard">
                      <Card.Body>
                        <h6>Soporte técnico</h6>
                        <h3>Software</h3>
                        <div className="labelContainer">
                          <span className="badge badge-lg">
                            <div className="icon sm prompts"></div>{" "}
                            <strong>+1200 </strong>recursos
                          </span>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <Button variant="link">
                          Comenzar chat
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="chatCard">
                      <Card.Body>
                        <h6>Clientes</h6>
                        <h3>Contacto y atención al cliente</h3>
                        <div className="labelContainer">
                          <span className="badge badge-lg">
                            <div className="icon sm prompts"></div>{" "}
                            <strong>+50 </strong>recursos
                          </span>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <Button variant="link">
                          Comenzar chat
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="chatCard">
                      <Card.Body>
                        <h6>Base empresarial</h6>
                        <h3>Operaciones</h3>
                        <div className="labelContainer">
                          <span className="badge badge-lg">
                            <div className="icon sm prompts"></div>{" "}
                            <strong>+276 </strong>recursos
                          </span>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <Button variant="link">
                          Comenzar chat
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default Home;
