export const localDataName = "cloudchat"

export interface localDataI {
    accessToken: string,
    refreshToken: string,
}

export const saveLocalData = (localData: localDataI) => {
    localStorage.setItem(localDataName, JSON.stringify(localData))
}

export const cleanLocalData = () => {
    localStorage.removeItem(localDataName)
}

export const getUserDataFromToken = (token: string) => {
    // const tokenDecoded: authStateI = jwt(token)
    const userData = {
        id: 1,
        nombre: "Espin",
        accessToken: "string",
        refreshToken: "string",
        permisos: [],
    };
    return userData
}