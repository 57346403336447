import { ChatMsgI } from "../ConversacionChat";

interface PropsI {
    chatMsg: ChatMsgI;
}

export const UserChatMsg = ({
    chatMsg,
}: PropsI) => {
    return (
        <div className="chatBubble cb_question">
            <div className="chatBubbleContainer singleLine">
                <div className="card">
                    <div className="card-body">
                        <p className="mb-0">{chatMsg.mensaje}</p>
                    </div>
                </div>
                <div className="avatarContainer right">
                    <div className="avatarImg">
                        <img
                            src="assets/img/avatar-1.jpg"
                            width="100%"
                            alt="GoCloud IA Chat Avatar"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
