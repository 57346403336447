import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../store/store';
// import { PUBLIC_ROUTE } from 'routes/publicRoutes';
import { PUBLIC_ROUTE } from '../../routes/publicRoutes';
import { PermisoI } from '../../store/slices/authSlice/authSlice';

interface PropsI {
    children: ReactNode;
    permisosRequeridos?: PermisoI[],
}

export const PrivateRoute = ({ children, permisosRequeridos }: PropsI) => {
    const { isLogged, permisos } = useSelector((st: RootState) => st.authData)

    return (<> {
        (
            isLogged &&
            (
                permisosRequeridos ?
                    (
                        permisos ?
                            permisosRequeridos.every(ctPermisoReq => permisos.some((ctPermiso: PermisoI) => ctPermiso.id == ctPermisoReq.id))
                            :
                            false
                    )
                    :
                    true
            )
        )
            ?
            children
            :
            <Navigate to={PUBLIC_ROUTE.LOGIN}></Navigate>
    }
    </>
    )
}
