import { ReactNode } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { PRIVATE_ROUTE } from '../../routes/privateRoutes'
import { logout } from '../../store/slices/authSlice/authSlice'
// import { NavLink } from 'react-bootstrap'

interface PropsI {
    children: ReactNode;
}

const DASH_SECTIONS = [
    { path: PRIVATE_ROUTE.DASH_METRICS, label: "Dashboard", title: "Dashboard", icon: <i className="bi bi-grid-1x2 me-2"></i> },
    { path: PRIVATE_ROUTE.DASH_CONVERSACIONES, label: "Conversaciones", title: "Conversaciones", icon: <i className="bi bi-chat-left-text me-2"></i> },
    { path: PRIVATE_ROUTE.DASH_FAQ, label: "FAQ", title: "Preguntas frecuentes", icon: <i className="bi bi-list-check me-2"></i> },
    { path: PRIVATE_ROUTE.HOME, label: "Chat", title: "Chat", icon: <i className="bi bi-chat me-2"></i> },
]

export const DashScreen = ({ children }: PropsI) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const getDashTitle = () => {
        const currentPath = location.pathname
        return DASH_SECTIONS.find(ctSec => ctSec.path == currentPath)?.title
    }

    return (
        <section id="dashboard">
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <div className="col-auto px-0">
                        <div id="sidebar" className="collapse-horizontal border-end collapse show" >
                            <div id="sidebar-nav" className="list-group border-0 rounded-0 text-sm-start">
                                <div className="d-flex flex-column flex-shrink-0 p-3 bg-light sidebarContainer" style={{ width: "280px" }}>
                                    <a href="index.html" className="dashboardLogo">
                                        <img src="assets/img/logo_GoCloud-color.svg" width="100%" alt="Logo GoCloud" />
                                    </a>
                                    <hr />
                                    <ul className="nav nav-pills flex-column mb-auto">
                                        {
                                            DASH_SECTIONS.map(ctSection =>
                                                <li key={ctSection.path}>
                                                    <NavLink to={ctSection.path}
                                                        className={({ isActive }: { isActive: boolean }) => "nav-link" + (isActive ? " active" : "")}
                                                    >
                                                        {ctSection.icon}{ctSection.label}
                                                    </NavLink>
                                                </li>
                                            )
                                        }
                                        <li>
                                            <Button variant="link" className="nav-link" style={{ color: "black" }} onClick={() => dispatch(logout())}><i className="bi bi-box-arrow-in-right me-2"></i>Log out</Button>
                                        </li>
                                    </ul>
                                    <hr />
                                    <div className="dropdown">
                                        <a href="#" className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="assets/img/avatar-1.jpg" alt="" width="32" height="32" className="rounded-circle me-2" />
                                            Nombre Apellido
                                        </a>
                                        <ul className="dropdown-menu dashboardProfile" >
                                            <li><a className="dropdown-item" href="miperfil.html"><i className="bi bi-person me-2"></i>Mi perfil</a></li>
                                            <li><a className="dropdown-item" href="configuracion.html"><i className="bi bi-nut me-2"></i>Configuración</a></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><a className="dropdown-item" href="login.html"><i className="bi bi-box-arrow-in-right me-2"></i>Log out</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <main className="col dashboardBody">
                        <a href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="sidebarCollapseBtn" aria-expanded="true"><i className="bi bi-list bi-lg py-2 p-1"></i></a>
                        <div className="page-header">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h1>{getDashTitle()}</h1>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="datePicker">
                                        <input className="dateIcon" type="date" id="startDate" name="startDate" />
                                        <i className="bi bi-dash mx-1"></i>
                                        <input className="dateIcon" type="date" id="EndDate" name="EndDate" />
                                    </div>

                                </div>
                            </div>

                        </div>
                        <hr />
                        {children}
                    </main>
                </div>
            </div>
        </section>
    )
}
