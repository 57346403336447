import { createSlice } from "@reduxjs/toolkit";
import { cleanLocalData, getUserDataFromToken, localDataName, saveLocalData } from "./authUtils";

export interface PermisoI {
    id: number;
    nombre: string;
}

export interface authStateI {
    id: string | number;
    nombre: string;
    accessToken: string;
    refreshToken: string;
    isLogged: boolean;
    permisos: PermisoI[] | null;
}

const rawLocalData = localStorage.getItem(localDataName);

const defaultState: authStateI = {
    id: "",
    nombre: "",
    accessToken: "",
    refreshToken: "",
    isLogged: false,
    permisos: null,
};

const initialState: authStateI = rawLocalData ?
    {
        ...getUserDataFromToken(rawLocalData),
        isLogged: true,
    }
    :
    defaultState

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            const token = action.payload.token
            saveLocalData(token)
            const userData = getUserDataFromToken(token)
            return {
                ...userData,
                isLogged: true,
            }
        },
        logout: (state) => {
            cleanLocalData()
            return defaultState
        },
    },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
