import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { PrivateRoute } from "./routes/guards/PrivateRoute";
import { PublicRoute } from "./routes/guards/PublicRoute";
import { PRIVATE_ROUTE } from "./routes/privateRoutes";
import { PUBLIC_ROUTE } from "./routes/publicRoutes";
import { ChatScreen } from "./screens/Chat/ChatScreen";
import { DashScreen } from "./screens/Dash/DashScreen";
import { ConversacionesSection } from "./screens/Dash/sections/Conversaciones/ConversacionesSection";
import { FAQSection } from "./screens/Dash/sections/FAQ/FAQSection";
import { MetricsSection } from "./screens/Dash/sections/Metrics/MetricsSection";
import { FAQScreen } from "./screens/FAQ/FAQScreen";
import Home from "./screens/Home/Home";
import { Login } from "./screens/Login/Login";
import "./styles/general.css";
import "./styles/palette.css";
import "./styles/text.css";
import "./styles/variables.css";

/* const Home = lazy(() => import("./screens/Home/Home"));
const CrearGrilla = lazy(() => import("./screens/CrearGrilla/CrearGrilla"));
const ChatSearch = lazy(() =>
  import("./screens/Docentes/ChatSearch/ChatSearch")
);
const DocentesEdit = lazy(() =>
  import("./screens/Docentes/DocentesEdit/DocentesEdit")
); */

function App() {
  const location = useLocation();
  const props = location.state?.props ?? {};

  const showHeaderAndFooter = () => {
    return location.pathname !== PUBLIC_ROUTE.LOGIN; // Oculta el encabezado y el pie de página en la ruta de inicio de sesión
  };

  const hideHeader = () => {
    return location.pathname.includes(PRIVATE_ROUTE.DASH);
  }

  return (
    <>
      {showHeaderAndFooter() && !hideHeader() && <Header />}
      <Routes>
        <Route path={PUBLIC_ROUTE.LOGIN} element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route path={PRIVATE_ROUTE.HOME} element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        } />
        <Route path={PRIVATE_ROUTE.CHAT_UNO} element={
          <PrivateRoute>
            <ChatScreen />
          </PrivateRoute>
        } />
        <Route path={PRIVATE_ROUTE.CHAT_DOS} element={
          <PrivateRoute>
            <ChatScreen />
          </PrivateRoute>
        } />

        <Route path={PRIVATE_ROUTE.DASH} element={
          <PrivateRoute>
            <DashScreen />
          </PrivateRoute>
        } />

        <Route path={PRIVATE_ROUTE.FAQ} element={
          <PrivateRoute>
            <FAQScreen />
          </PrivateRoute>
        } />

        {/* DASH */}
        <Route path={PRIVATE_ROUTE.DASH_FAQ} element={
          <PrivateRoute>
            <DashScreen><FAQSection /></DashScreen>
          </PrivateRoute>
        } />

        <Route path={PRIVATE_ROUTE.DASH_CONVERSACIONES} element={
          <PrivateRoute>
            <DashScreen><ConversacionesSection /></DashScreen>
          </PrivateRoute>
        } />

        <Route path={PRIVATE_ROUTE.DASH_METRICS} element={
          <PrivateRoute>
            <DashScreen><MetricsSection /></DashScreen>
          </PrivateRoute>
        } />

        <Route path={PRIVATE_ROUTE.DASH} element={<PublicRoute><Navigate to={PRIVATE_ROUTE.DASH_METRICS} /></PublicRoute>}></Route>

        <Route path={"/*"} element={<PublicRoute><Navigate to={PUBLIC_ROUTE.LOGIN} /></PublicRoute>}></Route>
      </Routes>
      {showHeaderAndFooter() && <Footer />}
    </>
  );
}

export default App;
