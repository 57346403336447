import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SelectOptionsService from "../../services/selectOptions";

const defaultState = {
  anios: [],
  dias: [],
  materias: [],
  planes: [],
  horas: [],
  docentes: [],
  grillas: [],
};

const initialState = defaultState;

export const fetchAños = createAsyncThunk("select/años", async (state) => {
  return await SelectOptionsService.getAños();
});

export const fetchDias = createAsyncThunk("select/dias", async (state) => {
  return await SelectOptionsService.getDias();
});

export const fetchPlanes = createAsyncThunk("select/plan", async (state) => {
  return await SelectOptionsService.getPlanes();
});

export const fetchGrillas = createAsyncThunk(
  "select/grillas",
  async (state) => {
    return await SelectOptionsService.getGrillas({
      institucionId: state.institucionId,
    });
  }
);

export const fetchDocentes = createAsyncThunk(
  "select/docentes",
  async (state) => {
    const { isSearched } = state;
    if (isSearched) {
    }

    return await SelectOptionsService.getDocentes();
  }
);

export const fetchMaterias = createAsyncThunk(
  "select/materias",
  async (state) => {
    return await SelectOptionsService.getMaterias(state);
  }
);

export const fetchHoras = createAsyncThunk("select/horas", async (state) => {
  const { grillaId, diaId } = state;
  return await SelectOptionsService.getHoras(diaId, grillaId);
});

export const selectOptionsSlice = createSlice({
  name: "selectOptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAños.fulfilled, (state, action) => {
      state.anios = action.payload;
    });
    builder.addCase(fetchDias.fulfilled, (state, action) => {
      state.dias = action.payload;
    });
    builder.addCase(fetchPlanes.fulfilled, (state, action) => {
      state.planes = action.payload;
    });
    builder.addCase(fetchMaterias.fulfilled, (state, action) => {
      state.materias = action.payload;
    });
    builder.addCase(fetchHoras.fulfilled, (state, action) => {
      state.horas = action.payload;
    });
    builder.addCase(fetchGrillas.fulfilled, (state, action) => {
      state.grillas = action.payload;
    });
    builder.addCase(fetchDocentes.fulfilled, (state, action) => {
      state.docentes = action.payload;
    });
  },
});

export const {} = selectOptionsSlice.actions;

export default selectOptionsSlice.reducer;
