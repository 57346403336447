import React from 'react'
import { Accordion } from 'react-bootstrap'

export const FAQAccordion = () => {
    return (
        <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Pregunta una va acá</Accordion.Header>
                <Accordion.Body>
                    Respuesta a la primera pregunta va acá. Respuesta a la primera pregunta va acá. Respuesta a la primera pregunta va acá. Respuesta a la primera pregunta va acá.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
                <Accordion.Header>Pregunta dos va acá</Accordion.Header>
                <Accordion.Body>
                    Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
                <Accordion.Header>Pregunta tress va acá</Accordion.Header>
                <Accordion.Body>
                    Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
