export const PRIVATE_ROUTE = {
	HOME: "/home",
	CHAT_UNO: "/chat-juc",
	CHAT_DOS: "/chat-seguro",
	FAQ: "/faq",

	DASH: "/dashboard",
	DASH_METRICS: "/dashboard/metrics",
	DASH_CONVERSACIONES: "/dashboard/conversaciones",
	DASH_FAQ: "/dashboard/FAQ",
};
