import { createContext, useState } from "react";
import LoadingOverlay from "../components/utils/LoadingOverlay";

const UtilsContext = createContext({});

export const UtilsProvider = ({ children }) => {
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  return (
    <UtilsContext.Provider
      value={{ showLoadingOverlay, setShowLoadingOverlay }}
    >
      {showLoadingOverlay && <LoadingOverlay />}
      {children}
    </UtilsContext.Provider>
  );
};

export default UtilsContext;
