import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice/authSlice";
import appSlice from "./slices/appSlice";
import docentesModalSlice from "./slices/docentesModalSlice";
import selectOptionsSlice from "./slices/selectOptionsSlice";
import cambiosDetectadosSlice from "./slices/cambiosDetectadosSlice";

export const store = configureStore({
  reducer: {
    authData: authSlice,



    appData: appSlice,
    modalsData: docentesModalSlice,
    selectOptions: selectOptionsSlice,
    cambiosDetectados: cambiosDetectadosSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
