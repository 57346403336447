import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { login } from "../../store/slices/authSlice/authSlice";
import "./login.css";

export const Login = () => {
  // const withTraditionalForm =
  //   process.env.REACT_APP_SHOW_TRADITIONAL_LOGIN_FORM == "true";
  const dispatch = useDispatch()

  const handleSubmit = async (e: any) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    console.log(JSON.stringify(formJson));

    // formData.append("app", 1);

    try {
      // const data = await axios.post(`${process.env.REACT_APP_API}/login`, formData);
      if (
        // formJson.username === "user@user.com" &&
        // formJson.password === "test"
        true
      ) {
        console.log("AAA")
        dispatch(login({ token: "ASD" }))

      } else throw new Error("Usuario y/o contraseña inválidos");
    } catch (err: any) {
      console.log(err.message);
      alert(err.message);
    }

    return;
  };

  return (
    <main className="flex-shrink-0">
      <section id="login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-8 col-12 mx-auto">
              <div className="card chatAi">
                <div className="card-body">
                  <div className="loginLogo">
                    <img
                      src="assets/img/logo_GoCloud-color.svg"
                      width="100%"
                      alt="GoCloud AI"
                    />
                    <span className="logoAppend">IA</span>
                  </div>
                  <h5 className="mb-4">Login de usuario</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label mb-0"
                      >
                        Usuario
                      </label>
                      <input
                        type="email"
                        name="username"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label mb-0"
                      >
                        Contraseña
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="exampleInputPassword1"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <div className="mb-3 form-check" style={{ paddingLeft: "0rem" }}>
                          <Form.Check
                            label={`Recordarme`}
                            id={`recordarmeCheckBox`}
                            style={{ marginBottom: 0 }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <a href="#" className="text-link">
                          Olvidé mi contraseña
                        </a>
                      </div>
                    </div>
                    <input
                      type="submit"
                      className="btn btn-primary w-100"
                      value="Ingresar"
                    />
                  </form>
                  <div className="row mt-4">
                    <div className="col-md-12 col-12 text-center">
                      <p className="text-divider">O ingresar con</p>
                    </div>


                    <div className="col-md-4 col-12 mb-3">
                      <a href="#" className="btn btn-login-si">
                        <img
                          src="assets/css/icons/login_google.svg"
                          width="100%"
                          alt="GoCloud login google"
                        />
                        Google
                      </a>
                    </div>


                    <div className="col-md-4 col-12 mb-3">
                      <a href="#" className="btn btn-login-si">
                        <img
                          src="assets/css/icons/login_twitter.svg"
                          width="100%"
                          alt="GoCloud login twitter"
                        />
                        Twitter
                      </a>
                    </div>


                    <div className="col-md-4 col-12 mb-3">
                      <a href="#" className="btn btn-login-si">
                        <img
                          src="assets/css/icons/login_github.svg"
                          width="100%"
                          alt="GoCloud login github"
                        />
                        Github
                      </a>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
