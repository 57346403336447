import { RecursoI } from "../../ChatScreen";
import { IaChatMsg } from "./components/IaChatMsg";
import { UserChatMsg } from "./components/UserChatMsg";

interface PropsI {
    conversacion: ChatMsgI[];
}

export interface ChatMsgI {
    remitente: "IA" | "user";
    mensaje: string;
    adjuntos?: RecursoI[];
}

export const ConversacionChat = ({
    conversacion,
}: PropsI) => {
    return (
        <>
            {
                conversacion.length == 0 ?
                    <p>
                        Mensaje de que no hay chat o bienvenida, etc
                    </p>
                    :
                    conversacion.map(ctMsg => <>
                        {ctMsg.remitente == "user" && <UserChatMsg chatMsg={ctMsg} />}

                        {ctMsg.remitente == "IA" && <IaChatMsg chatMsg={ctMsg} />}
                    </>)
            }

        </>
    )
}
