import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  cambiosDetectados: [],
};

const initialState = defaultState;

export const cambiosDetectadosSlice = createSlice({
  name: "cambiosDetectados",
  initialState,
  reducers: {
    setCambiosDetectados: (state, action) => {
      state.cambiosDetectados = action.payload;
    },
  },
});

export const { setCambiosDetectados } =
  cambiosDetectadosSlice.actions;

export default cambiosDetectadosSlice.reducer;
