import { Col, Row } from "react-bootstrap"
import "./recursosTable.css"
import { RecursoI, RecursoType } from '../../ChatScreen';

interface PropsI {
    recursos: RecursoI[]
}
export const getClassIconRecurso = (recursoType: RecursoType) => {
    const OPCIONES_ICONOS: { [key in RecursoType]: string } = {
        "API": "api",
        "PDF": "pdf_file",
        "drive": "google_drive",
        "URL": "url",
        "REDMINE": "redmine",
        "JIRA":"jira",
        "MySQL":"mysql",
        "EXCEL":"excel_file",
        "DISCORD" : "discord"
        

    }
    return OPCIONES_ICONOS[recursoType]
}

export const RecursosTable = ({
    recursos
}: PropsI) => {
    return (
        <div style={{}}>
            <Row style={{ gap: 0, padding: "4px 8px 0px 8px" }}>
                <Col sm={9}>
                    <h1 className="recursosTable-title">
                        RECURSOS
                    </h1>
                </Col>
                <Col sm={3}>
                    <h1 className="recursosTable-title" style={{ float: "right" }}>
                        ESTADO
                    </h1>
                </Col>
            </Row>
            <div style={{ marginTop: "-1px" }}>
                {recursos.map(ctRecurso =>
                    <>
                        <Row className="recursosTable-row">
                            <Col sm={9} style={{ display: "flex", alignItems: "center", paddingRight: 0, }}>
                                <div className={`icon lg ${getClassIconRecurso(ctRecurso.type)}`}></div>
                                <div style={{ marginBottom: "2px" }}>
                                    <p className="recursosTable-text">
                                        {ctRecurso.title}
                                    </p>
                                    {
                                        ctRecurso.subtitle &&
                                        <p className="recursosTable-text small">
                                            {ctRecurso.subtitle}
                                        </p>
                                    }
                                </div>
                            </Col>
                            <Col sm={3} style={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "2px", paddingLeft: 0, paddingRight: 0 }}>
                                <p className="recursosTable-text">
                                    {ctRecurso.status} <i className="bi bi-check-circle bi-success ms-2"></i>
                                </p>
                            </Col>
                        </Row>
                    </>
                )}
            </div>


        </div>
    )
}
