import React from 'react'
import { Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const FAQScreen = () => {
    return (
        <section id="chatFaq">
            <div className="container-fluid">
                <div className="row leftColumn">
                    <div className="col-xl-8 col-lg-7 col-12 bg-chat order-md-1 order-2">
                        <div className="row pt-4">
                            <div className="col-md-10 col-11 mx-auto pt-4">
                                <div className="col-md-12 col-12 my-4 pb-1">
                                    <h2>Preguntas frecuentes</h2>
                                </div>
                                <div className="card dashboard">
                                    <div className="card-body pb-2">
                                        <Accordion defaultActiveKey="0" flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Pregunta una va acá</Accordion.Header>
                                                <Accordion.Body>
                                                    Respuesta a la primera pregunta va acá. Respuesta a la primera pregunta va acá. Respuesta a la primera pregunta va acá. Respuesta a la primera pregunta va acá.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Pregunta dos va acá</Accordion.Header>
                                                <Accordion.Body>
                                                    Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá. Respuesta a la segunda pregunta va acá.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Pregunta tress va acá</Accordion.Header>
                                                <Accordion.Body>
                                                    Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá. Respuesta a la tercera pregunta va acá.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-4 col-lg-5 col-12 order-md-2 order-1 px-0">
                        <div className="sideContainer">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <h5>Áreas de interés</h5>
                                </div>

                                <div className="col-md-12 col-12 mb-3">
                                    <div className="sideCard">
                                        <div className="row">

                                            <div className="col-md-12 col-12">
                                                <div className="sideCardRow ps-0">
                                                    <Link to="#" className="sourceContainer">
                                                        <div>
                                                            <p style={{ lineHeight: "1.5" }}>Integración del Chat con tus aplicaciones</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-12">
                                                <div className="sideCardRow ps-0">
                                                    <Link to="#" className="sourceContainer">
                                                        <div>
                                                            <p style={{ lineHeight: "1.5" }}>Servicio técnico que ofrecemos desde la app</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-12">
                                                <div className="sideCardRow ps-0">
                                                    <Link to="#" className="sourceContainer">
                                                        <div>
                                                            <p style={{ lineHeight: "1.5" }}>GoCloud AI trabaja sobre la siguiente tecnología</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-12">
                                                <div className="sideCardRow ps-0">
                                                    <Link to="#" className="sourceContainer">
                                                        <div>
                                                            <p style={{ lineHeight: "1.5" }}>Documentación para integar la API</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
