import { getToken } from "../auth/helpers";
import { axiosInstance } from "../api/axios";

interface GetGrillasI {
  institucionId: number;
}

interface SearchOptions {
  type: string;
  search: string;
}

interface GetDocentesOptions {
  filters?: SearchOptions;
}

const SelectOptionsService = {
  getAños: () =>
    axiosInstance.httpApiGet({
      url: "anios",
      controller: "",
    }),
  getDias: () =>
    axiosInstance.httpApiGet({
      url: "dias",
      controller: "",
    }),
  getGrillas: ({ institucionId }: GetGrillasI) =>
    axiosInstance.httpApiGet({
      url: `grillas/labels/${institucionId}`,
      controller: "",
      /*  headers: {
      Authorization: 'Bearer ' + getToken(),
      } */
    }),
  getPlanes: () =>
    axiosInstance.httpApiGet({
      url: "plan",
      controller: "",
    }),
  getDocentes: (options?: GetDocentesOptions) => {
    return axiosInstance.httpApiGet({
      url: "docentes/docente/labels",
      controller: "",
      data: options,
    });
  },

  getHoras: (diaId: string, grillaId: string) => {
    return axiosInstance.httpApiGet({
      url: "hora",
      data: { diaId, grillaId },
      controller: "",
    });
  },
  getMaterias: (planId: number) => {
    return axiosInstance.httpApiGet({
      url: "asignatura",
      data: { planId },
      controller: "",
    });
  },
};

export { SelectOptionsService as default };
