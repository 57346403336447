import axios, { AxiosInstance } from "axios";
import qs from "qs";
import { API_URL } from "../config";
import { authStateI, logout } from "../store/slices/authSlice/authSlice";
import { RootState, store } from "../store/store";
import {
  AxiosInstanceI,
  HttpApiDeleteI,
  HttpApiGetFileI,
  HttpApiGetI,
  HttpApiPatchI,
  HttpApiPostI,
  HttpApiUploadFileI,
} from "./interfaces";

const axiosI: AxiosInstance = axios.create({
  baseURL: API_URL,
});

const catchError = (error: any) => {
  if (axios.isCancel(error)) {
    throw new ApiError("Cancelado", 500);
  }
  if (error.response) {
    throw new ApiError(error.response.data.message, error.response.status);
  }
  throw new ApiError("Hubo un error", 500);
};

async function httpApiGet<T>({
  url,
  data = null,
  controller = null,
  headers = {},
}: HttpApiGetI): Promise<T> {

  const storeState: RootState = store.getState();
  const authData: authStateI = storeState.authData

  const authHeader =
    authData && authData.accessToken
      ? { Authorization: `Bearer ${authData.accessToken}` }
      : {};

  try {
    const response = await axiosI.get<T>(url, {
      params: data ? data : null,
      signal: controller ? controller.signal : null,
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...authHeader,
      },
      paramsSerializer: function (data: {}) {
        return qs.stringify(data, { arrayFormat: "repeat" });
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status == 401) {
      store.dispatch(logout());
    }

    return catchError(error);
  }
}

async function httpApiGetFile({
  url,
  data = null,
  controller = null,
  headers = {},
}: HttpApiGetFileI) {
  try {
    const response = await axiosI.get(url, {
      params: data,
      signal: controller ? controller.signal : null,
      headers,
      responseType: "blob",
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status == 401) {
      store.dispatch(logout());
    }
    catchError(error);
  }
}

// TODO: Laburar el agregado de token a las peticiones GET + POST segun corresponda

async function httpApiPost({
  url,
  data = {},
  controller = null,
  headers = {},
}: HttpApiPostI) {
  const storeState: RootState = store.getState();
  const authData: authStateI = storeState.authData

  const authHeader =
    authData && authData.accessToken
      ? { Authorization: `Bearer ${authData.accessToken}` }
      : {};

  try {
    const response = await axiosI.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...authHeader,
      },
      signal: controller ? controller.signal : null,
    });
    return response;
  } catch (error: any) {
    if (error.response.status == 401) {
      store.dispatch(logout());
    }
    catchError(error);
  }
}

async function httpApiPatch({
  url,
  data = {},
  controller = null,
  headers = {},
}: HttpApiPatchI) {
  try {
    const response = await axiosI.patch(url, data, {
      headers: { "Content-Type": "application/json", ...headers },
      signal: controller ? controller.signal : null,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status == 401) {
      store.dispatch(logout());
    }
    catchError(error);
  }
}

async function httpApiUploadFile({
  url,
  file = null,
  controller = null,
  headers = {},
}: HttpApiUploadFileI) {
  try {
    const response = await axiosI.post(url, file, {
      headers: { "Content-Type": "multipart/form-data", ...headers },
      signal: controller ? controller.signal : null,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status == 401) {
      store.dispatch(logout());
    }
    catchError(error);
  }
}

async function httpApiDelete({
  url,
  data = null,
  controller = null,
  headers = {},
}: HttpApiDeleteI) {
  try {
    const response = await axiosI.delete(url, {
      data,
      /* headers: { "Content-Type": "multipart/form-data", ...headers }, */
      signal: controller ? controller.signal : null,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status == 401) {
      store.dispatch(logout());
    }
    catchError(error);
  }
}

const axiosInstance: AxiosInstanceI = {
  httpApiGet,
  httpApiGetFile,
  httpApiPost,
  httpApiPatch,
  httpApiUploadFile,
  httpApiDelete,
};

export class ApiError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}
export { axiosInstance };

