import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  editarDatosDocentes: {},
  docentesModal: false,
};

const initialState = defaultState;


export const docentesModalSlice = createSlice({
  name: "docentesModal",
  initialState,
  reducers: {
    setOpenModalAndSetDatosDocentes: (state, action) => {
      state.editarDatosDocentes = action.payload.datosDocentes;
      state.docentesModal = action.payload.docentesModal
    },
  
  },
});

export const { setOpenModalAndSetDatosDocentes } =
  docentesModalSlice.actions;

export default docentesModalSlice.reducer;
