import { createSlice } from "@reduxjs/toolkit";
import { InstitucionI, RolI } from '../../interfaces/instucion';

export interface appDataState {
    instituciones: InstitucionI[] | null,
    institucion_seleccionada: InstitucionI | null,
    rol_seleccionado: RolI | null,
}

const defaultState: appDataState = {
    instituciones: null,
    institucion_seleccionada: null,
    rol_seleccionado: null,
};

const initialState: appDataState = defaultState;

export const appDataSlice = createSlice({
    name: "appData",
    initialState,
    reducers: {
        addAppData: (state, action) => {
            const payload = action.payload
            return payload ? { ...state, ...payload, } : state;
        },
        cleanAppData: (state: appDataState, action) => {
            const { appDataKey }: { appDataKey: string } = action.payload
            delete state[appDataKey as keyof appDataState];
            return state;
        }
    },
});

export const { addAppData, cleanAppData } = appDataSlice.actions;

export default appDataSlice.reducer;
