import React from 'react'
import { FAQAccordion } from '../../../FAQ/components/FAQAccordion'
import { Row } from 'react-bootstrap'

export const FAQSection = () => {
    return (
        <Row>
            <div className="col-md-12 col-12 my-4">
                <h2>Seleccione del listado</h2>
            </div>

            <div className="col-md-8 col-sm-8 col-12 mb-4">
                <div className="card dashboard dbh-100">
                    <div className="card-body pb-2">
                        <FAQAccordion />
                    </div>
                </div>
            </div>

        </Row>
    )
}
