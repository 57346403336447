import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { PRIVATE_ROUTE } from '../../routes/privateRoutes';

interface PropsI {
    children: ReactNode
}

export const PublicRoute = ({ children }: PropsI) => {
    const { isLogged } = useSelector((st: RootState) => st.authData)

    return (<> {
        !isLogged ?
            children
            :
            <Navigate to={PRIVATE_ROUTE.HOME}></Navigate>
    }
    </>
    )
}
