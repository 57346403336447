import React, { useState } from 'react'
import { ChatMsgI } from '../ConversacionChat';
import { getClassIconRecurso } from '../../RecursosTable/RecursosTable';
import { Button } from 'react-bootstrap';

interface PropsI {
    chatMsg: ChatMsgI
}

export const IaAvatar = () => {

    return <div className="avatarContainer left">
        <div className="avatarImg">
            <img
                src="assets/img/avatarGoCloud.jpg"
                width="100%"
                alt="GoCloud IA Chat Avatar"
            />
        </div>
    </div>
}

export const IaChatMsg = ({
    chatMsg
}: PropsI) => {
    const maxAnswerLength = 200;
    const isShowableAnswer = chatMsg.mensaje.length > maxAnswerLength;

    const [showCompleto, setShowCompleto] = useState(false)

    const handleCollapseAnswer = () => {
        setShowCompleto(false)
    };

    const handleExpandAnswer = () => {
        setShowCompleto(true)
    };

    const renderText = () => {
        const msg = chatMsg.mensaje
        if (!isShowableAnswer) return msg

        return showCompleto ?
            msg
            :
            msg.toString().substring(0, maxAnswerLength) +
            (maxAnswerLength < msg?.length ? "..." : "")

    }

    return (
        <div className="chatBubble cb_answer">
            <div className="chatBubbleContainer">
                <IaAvatar />
                <>
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <p className="platformName">GoCloud IA</p>
                                </div>
                                <div className="col-md-6 col-6">
                                    <a className="feedbackLink">
                                        <div className="icon md feedback"></div>{" "}
                                        Feedback
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <p className="firstLine">{renderText()}</p>
                            <div className="collapse" id="collapseExample">

                            </div>
                            <div className="chatControls">
                                {
                                    isShowableAnswer && <>
                                        {
                                            showCompleto ?
                                                <Button
                                                    variant="link"
                                                    onClick={handleCollapseAnswer}
                                                >
                                                    <div className="icon sm resumir"></div>{" "}
                                                    Resumir
                                                </Button>
                                                :
                                                <Button
                                                    variant="link"
                                                    onClick={handleExpandAnswer}
                                                >
                                                    <div className="icon sm ampliar"></div>{" "}
                                                    Ampliar
                                                </Button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        {
                            chatMsg.adjuntos && chatMsg.adjuntos.length > 0 &&
                            <div className="card-footer">
                                {
                                    chatMsg.adjuntos && chatMsg.adjuntos.map(ctRecurso => <>
                                        <span className="badge badge-lg">
                                            <div className={`icon sm ${getClassIconRecurso(ctRecurso.type)}`}></div>{" "}
                                            {ctRecurso.title}
                                        </span>
                                    </>)
                                }
                            </div>
                        }
                    </div>
                </>
            </div>
        </div>
    )
}
